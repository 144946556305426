import axios from 'axios'
const config = require('../../config');

const state = {}
const getters = {}
const mutations = {}
const actions = {

  async getEvents (_, obj) {
    let query = `${config.baseUrl}/client/get/events`;
    const params = [];
    if (obj.startDate != null) {
      params.push(`startDate=${obj.startDate}`);
    }
    if (obj.endDate != null) {
      params.push(`endDate=${obj.endDate}`);
    }
    if (params.length > 0) {
      query += `?${params.join('&')}`;
    }
    const resp = await axios.get(query);
    return resp.data;
  },

  async addEvent (_, obj) {

    let query = `${config.baseUrl}/client/add/event`;
    const params = [];
    if (obj.startDate != null) {
      params.push(`&name=${obj.eventName}`);
    }
    if (obj.startDate != null) {
      params.push(`&dateStart=${obj.startDate}`);
    }
    if (obj.scanDateStart != null) {
      params.push(`&scanDateStart=${obj.scanDateStart}`);
    }
    if (obj.scanDateEnd != null) {
      params.push(`&scanDateEnd=${obj.scanDateEnd}`);
    }
    if (obj.clientId != null) {
      params.push(`&clientId=${obj.clientId}`);
    }
    if (obj.id != null) {
      params.push(`&id=${obj.id}`);
    }
    if (params.length > 0) {
      query += `?${params.join('&')}`;
    }
    const resp = await axios.get(query);
    return resp.data;
  },
  async addBarcodes (_, obj) {
    let query = `${config.baseUrl}/client/add/barcodes`;
    const params = [];
    if (obj.eiid != null) {
      params.push(`&eiid=${obj.eiid}`);
    }
    if (obj.barcodes != null) {
      params.push(`&barcodes=${obj.barcodes}`);
    }
    if (obj.noScans != null) {
      params.push(`&noScans=${obj.noScans}`);
    }
    if (params.length > 0) {
      query += `?${params.join('&')}`;
    }
    const resp = await axios.get(query);
    return resp.data;
  },
  async resetEvent (_, obj) {
    let query = `${config.baseUrl}/client/reset/event`;
    const params = [];
    if (obj.eiid != null) {
      params.push(`&eiid=${obj.eiid}`);
    }
    if (obj.clearHistory != null) {
      params.push(`&clearHistory=${obj.clearHistory}`);
    }
    if (obj.noScans != null) {
      params.push(`&noScans=${obj.noScans}`);
    }
    if (obj.password != null) {
      params.push(`&password=${obj.password}`);
    }
    if (params.length > 0) {
      query += `?${params.join('&')}`;
    }
    const resp = await axios.get(query);
    return resp.data;
  },
  async getEvent (_, obj) {

    let query = `${config.baseUrl}/client/get/event`;
    if (obj.id != null) {
      query += `?id=${obj.id}`;
    } 
    const resp = await axios.get(query);
    return resp.data;
  },

}

export default {
  state,
  getters,
  mutations,
  actions,
}