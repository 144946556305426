import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/LoginPage.vue'),
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('@/views/EventsTable.vue'),
  },
  {
    path: '/add-event',
    name: 'AddEvent',
    component: () => import('@/views/AddEvent.vue'),
  },
  {
    path: '/event/:id/edit-event',
    name: 'EditEvent',
    component: () => import('@/views/EditEvent.vue'),
  },
  {
    path: '/scans',
    name: 'Scans',
    component: () => import('@/views/ScansPage.vue'),
  },
  {
    path: '/event/:id',
    name: 'EventDetails',
    component: () => import('@/views/EventDetails.vue'),
  },
  {
    path: '/event/:id/add-barcodes',
    name: 'AddBarcodes',
    component: () => import('@/views/AddBarcodes.vue'),
    props: true,
  },
  {
    path: '/event/:id/reset',
    name: 'ResetEvent',
    component: () => import('@/views/ResetEvent.vue'),
    props: true,
  },
  {
    path: '/check-scan',
    name: 'CheckScan',
    component: () => import('@/views/CheckScan.vue'),
  },
  {
    path: '/scanners',
    name: 'ScannersList',
    component: () => import('@/views/Scanners.vue')
  },
  {
    path: '/scanners/:id/edit',
    name: 'EditScanner',
    component: () => import('@/views/EditScanner.vue'),
    props: true
  },
  {
    path: '/clients/:clientId/scanners/:id/edit',
    name: 'ClientEditScanner',
    component: () => import('@/views/EditScanner.vue'),
    props: true
  },
  {
    path: '/clients',
    name: 'ClientsList',
    component: () => import('@/views/Clients.vue')
  },
  {
    path: '/clients/:id/edit',
    name: 'EditClient',
    component: () => import('@/views/EditClient.vue'),
    props: true
  },
  {
    path: '/clients/:clientId/scanners',
    name: 'ClientScannersList',
    component: () => import('@/views/Scanners.vue'),
    props: true
  },
  {
    path: '/clients/:clientId/users',
    name: 'ClientUsersList',
    component: () => import('@/views/Users.vue'),
    props: true
  },
  {
    path: '/clients/:clientId/users/:id/edit',
    name: 'EditClientUser',
    component: () => import('@/views/EditClientUser.vue'),
    props: true
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
