import axios from 'axios'
axios.defaults.withCredentials = true;

const config = require('../../config');
import { notify } from "@kyvg/vue3-notification";

const state = {
  user: null
}

const getters = {
  getUser: state => state.user,
  isLoggedIn: state => state.user !== null
}

const mutations = {
  setUser: (state, user) => {
    state.user = user
  }
}

const actions = {
  async getProfile ({ commit }) {
    const resp = await axios.get(`${config.baseUrl}/client/get/profile`);
    if (resp.data.result == 1){
      commit('setUser', resp.data.data);
    } else {
      commit('setUser', null);
    }
  },
  async loginUser (_, obj) {
    try {
      let query = `${config.baseUrl}/scan/login?username=${obj.username}&password=${obj.password}`;
      const resp = await axios.get(query);
      if (resp.data.result == 1){
        this.dispatch("getProfile");
      }
      return resp.data;
    } catch(e) {
      notify({
        title: "Eroare de conexiune cu serverul.",
        type: "error",
      });
      return Promise.reject(e);
    }
    
  },
  async logout({commit}) {
    const resp = await axios.get(`${config.baseUrl}/scan/logout`);
    if (resp.data.result == 1){
      commit('setUser', null);
    }
    return resp.data;
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}