import axios from 'axios'
const config = require('../../config');

const state = {}
const getters = {}
const mutations = {}
const actions = {

  async getScanners (_, clientId) {
    let query = `${config.baseUrl}/scanner/list`;
    if (clientId != null){
      query+=`?clientId=${clientId}`;
    }
    const resp = await axios.get(query);
    if (resp.data.result == 1){
        return resp.data.data;
    } else {
        return [];
    }
  },

  async getScanner (_, id) {
    let query = `${config.baseUrl}/scanner/get?id=${id}`;
    const resp = await axios.get(query);
    if (resp.data.result == 1){
        return resp.data.data;
    } else {
        return [];
    }
  },

  async addScanner (_, scanner) {
    let query = `${config.baseUrl}/scanner/add`;
    const resp = await axios.post(query, scanner);
    return resp.data;
  },

  async deleteScanner (_, id) {
    let query = `${config.baseUrl}/scanner/delete?id=${id}`;
    const resp = await axios.get(query);
    return resp.data;
  }

}

export default {
  state,
  getters,
  mutations,
  actions,
}