import axios from 'axios'
const config = require('../../config');

const state = {}
const getters = {}
const mutations = {}
const actions = {

  async getClients () {
    let query = `${config.baseUrl}/client/list`;
    const resp = await axios.get(query);
    if (resp.data.result == 1){
        return resp.data.data;
    } else {
        return [];
    }
  },

  async getClient ({ dispatch }, id) {
    const Clients = await dispatch('getClients');
    return Clients.find(c => c.id == id);
  },

  async addClient (_, client) {
    let query = `${config.baseUrl}/client/add`;
    const resp = await axios.post(query, client);
    return resp.data;
  },

  async getClientUsers (_, id) {
    let query = `${config.baseUrl}/client/user/list?clientId=${id}`;
    const resp = await axios.get(query);
    if (resp.data.result == 1){
        return resp.data.data;
    } else {
        return [];
    }
  },

  async getClientUser (_, id) {
    let query = `${config.baseUrl}/client/user/get?id=${id}`;
    const resp = await axios.get(query);
    if (resp.data.result == 1){
        return resp.data.data;
    } else {
        return [];
    }
  },

  async addClientUser (_, {clientId, user}) {
    console.log(user);
    let query = `${config.baseUrl}/client/user/add?clientId=${clientId}`;
    const resp = await axios.post(query, user);
    return resp.data;
  },

  async deleteClientUser (_, id) {
    let query = `${config.baseUrl}/client/delete?id=${id}`;
    const resp = await axios.get(query);
    return resp.data;
  },

}

export default {
  state,
  getters,
  mutations,
  actions,
}