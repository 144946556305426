import Vuex from 'vuex'
import user from './modules/user'
import tickets from './modules/tickets'
import events from './modules/events'
import scanners from './modules/scanners'
import clients from './modules/clients'

export default new Vuex.Store({
  modules: {
    user,
    tickets,
    events,
    scanners,
    clients
  }
})